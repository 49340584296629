import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import {
    Typography,
    Card,
    Grid,
    CardActionArea,
    // CardActions,
    CardContent,
    // Button,
} from "@material-ui/core";
import Zoom from '@material-ui/core/Zoom';

// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

const useStyles = makeStyles((theme) => ({
    ProdImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '150px',
        paddingTop: 10,
        height: "150px",
        '& img': { 
            maxWidth: "150px"
        }
    },
    CardContent: {
        minHeight: "150px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    ProdName: {
        fontSize: '1rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineClamp: 2,
        lineHeight: '1.5rem',
        maxHeight: '3rem', 
        display: '-webkit-box',
        boxOrient: 'vertical'
    },
    ProdPrice: {
        fontWeight: "bold",
        fontSize: "1.5rem"
    }
}));

export default function ProductCards(props) {
    const products = props.products;
    const classes = useStyles();

    return (
        <React.Fragment>
            {products.map(prod => {

                var slugCategory = prod.category.category.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                var slugSubategory = prod.category.subcategory.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                var slugName = prod.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                return (

                    <Grid item lg={4} md={4} sm={6} xs={12} key={prod.id}>
                        <Zoom in={true} style={{ transitionDelay: '450ms' }}>
                            <Card>
                                <CardActionArea component={Link}
                                    to={`/produtos/${slugCategory}/${slugSubategory}/${slugName}?id=${prod.id}`}>
                                    <div className={classes.ProdImg}>
                                        <img
                                            src={prod.productMainImg.url}
                                            alt={prod.name}
                                            loading="lazy"
                                        />
                                    </div>
                                    <CardContent className={classes.CardContent}>
                                        <Typography variant="subtitle1" color="textPrimary" className={classes.ProdName}>{prod.name}</Typography >
                                        <Typography variant="subtitle2" color="textPrimary" className={classes.ProdPrice} >{prod.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Zoom>
                    </Grid>
                )
            })}
        </React.Fragment>
    )
}


