import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';

import { firestore } from '../../firebase';
import firebase from 'firebase';

import {
    useParams,
    useLocation
} from "react-router-dom";

import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import Loader from "../../components/Loader";
import EmptyState from "../../components/EmptyState";
import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { Refresh as RefreshIcon } from "@material-ui/icons";
import HomeIcon from '@material-ui/icons/Home';

import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Paper from '@material-ui/core/Paper';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Alert from '@material-ui/lab/Alert';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { green } from '@material-ui/core/colors';

import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import GalleryNav from './GalleryNav';
import ProductCarousel from '../../components/ProductCarousel/ProductCarousel.js';

import FetchCep from '../../util/FetchCep';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const useStyles = makeStyles((theme) => ({
    root: {
        '& table.pagamento': {
            borderCollapse: 'collapse'
        },
        '& table.pagamento td': {
            border: '1px solid #ccc',
            padding: '.4rem'
        },
        '& table.pagamento th': {
            fontWeight: 600,
            border: '1px solid #ccc',
            padding: '.4rem',
        },
        [theme.breakpoints.down('md')]: {
            '& table.pagamento th': {
                fontSize: '.8rem',
            },
            ' & table.pagamento td': {
                fontSize: '.8rem',
            }
        },
        '& img': {
            maxWidth: '100%'
        }
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        flex: '1 0 auto',
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    esgotado: {
        backgroundColor: '#dedede'
    },
    HeadingStyle: {
        fontSize: '2rem',
        fontWeight: 500,
        marginBottom: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.4rem'
        }
    },
    subTitle: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.1rem',
        }
    },

    breadcrumbs: {
        '& li': {
            color: '#2e2e2e',
        },
        '& a': {
            fontWeight: 700,
            color: '#e81919',
            transition: 'all 250ms',
            '&:hover': {
                color: '#2e2e2e',
                textDecoration: 'none'
            }
        }
    }

}));

const ProductPage = (props) => {
    const classes = useStyles();
    const products = props.products;
    // const categories = props.categories;
    const [quantity, setQuantity] = useState(1)
    const cart = props.cart["products"];
    let query = useQuery();
    let prodId = query.get("id");

    const { routeCategory, routeSubcategory } = useParams()
    // const [name, setName] = useState('');
    // const [value, setValue] = useState(0);
    // const [category, setCategory] = useState([]);
    // const [description, setDescription] = useState("<p></p>");
    // const [stock, setStock] = useState(1);
    // const [mainImg, setMainImg] = useState('');
    // const [productImages, setProductImages] = useState([]);
    // const [isNew, setIsNew] = useState('');
    const [actualProduct, setActualProduct] = useState({});
    // const [brand, setBrand] = useState('');

    const [cepData, setCEPData] = useState({
        uf: '',
        valor: ''
    })

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [backdropOpen, setBackdropOpen] = React.useState(false);



    //carrinho
    const [basket, setBasket] = useState([]);

    let history = useHistory();
    function homeRedirect() {
        history.push("/");
    }

    useEffect(() => {
        setBackdropOpen(true);
        if (products && prodId) {
            let _prodAtual = products.filter(item => item.id === prodId)
            // console.log(_prodAtual)
            setActualProduct(_prodAtual[0])
           
        } else {
            setLoading(false);
            setError(true);
        }
        // console.log(cart)
        setBackdropOpen(false);
        setLoading(false);
    }, [products, prodId]);

    useEffect(() => {
        setBasket(cart)
    }, [cart])



    const [relatedProds, setRelatedProds] = useState([])

    useEffect(() => {
        if (actualProduct) {
            if (actualProduct.category) {
                let relatedProducts = products.filter(item => item.category.subcategory.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase() === routeSubcategory)

                setRelatedProds(relatedProducts)
            }
        }
    }, [actualProduct, products, routeSubcategory]);

    function createMarkup() {
        return { __html: actualProduct.description };
    }

    const handleChange = (event) => {
        setQuantity(event.target.value);
        // setActualProduct({
        //     id: prodId,
        //     initialQtd: event.target.value
        // })
    };

    const createMenuQTD = (stock) => {
        let qtd = [];
        for (let i = 1; i <= stock; i++) {
            qtd.push(<MenuItem value={i} key={i}>{i}</MenuItem>)
        }
        return (qtd)
    }


    const addToBasket = () => {
        if (localStorage.getItem('@uaieconomizei/cartRef')) {
            //uses actual basket if exists

            var prodExists = false;
            var baskeItems = basket.map((prod) => {
                if (prod.id === prodId) {
                    prodExists = true;

                    if (prod.quantity >= actualProduct.stock) {
                        alert('Você selecionou a quantidade máxima disponivel para este produto.')
                        return ({ ...prod })
                    } else {
                        return ({
                            ...prod,
                            quantity: prod.quantity + 1
                        })
                    }

                } else {
                    return ({ ...prod })
                }
            })

            if (prodExists) {
                //updates if product exists
                firestore.collection('basket').doc(localStorage.getItem('@uaieconomizei/cartRef')).update({
                    products: baskeItems,
                    updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
                }).then(ref => {
                    // console.log('Atualizado!');
                });

            } else {
                //adds it if doesn't exists
                firestore.collection('basket').doc(localStorage.getItem('@uaieconomizei/cartRef')).update({
                    products: firebase.firestore.FieldValue.arrayUnion({
                        ...actualProduct,
                        quantity: quantity,
                        description: '',
                        productImages: ''
                    }),
                    updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
                }).then(ref => {
                    // console.log('Adicionado');
                });
            }

        } else {
            //creates a basket
            firestore.collection('basket').add({
                products: firebase.firestore.FieldValue.arrayUnion({
                    ...actualProduct,
                    quantity: quantity,
                    description: '',
                    productImages: ''
                }),
                createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
            }).then(ref => {
                // console.log('Added document with ID: ', ref.id);
                localStorage.setItem('@uaieconomizei/cartRef', ref.id);
            });
        }
    }

    const [cepValue, setCepValue] = useState(0);

    useEffect(() => {
        if (cepData.valor !== "") {
            setCepValue(cepData.valor)
        }
    }, [cepData])


    if (error) {
        return (
            <EmptyState
                image={<ErrorIllustration />}
                title="Não foi possivel encontrar o produto"
                description="Algo deu errado ao tentar recuperar o produto solicitado."
                button={
                    <React.Fragment>
                        <Fab
                            variant="extended"
                            color="primary"
                            onClick={() => window.location.reload()}
                            style={{ marginRight: '2rem' }}
                        >
                            <Box clone mr={1}>
                                <RefreshIcon />
                            </Box>
                            Recarregar
                        </Fab>
                        <Fab
                            variant="extended"
                            color="secondary"
                            onClick={homeRedirect}
                        >
                            <Box clone mr={1}>
                                <HomeIcon />
                            </Box>
                            Home
                        </Fab>
                    </React.Fragment>
                }

            />
        );
    }

    if (loading || !actualProduct) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{actualProduct.name} - Uai Economizei</title>
                <meta name="title" content={`${actualProduct.name} - Uai Economizei`} />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={`${actualProduct.name} - Uai Economizei.`} />
                <meta property="og:image" content={actualProduct.productMainImg.url} />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:url" content={window.location.href} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <CssBaseline />
            <Toolbar id="back-to-top-anchor" style={{ minHeight: 0 }} />
            <Container maxWidth="lg" disableGutters={true} className={classes.root} style={{ marginBottom: '4rem', minHeight: '100vh' }} >
                <div style={{ marginLeft: '8px', marginBotton: '2rem', marginTop: '2rem' }}>
                    <Breadcrumbs className={classes.breadcrumbs} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/">
                            Home
                        </Link>
                        <Link component={RouterLink} color="inherit" to={`/produtos/${routeCategory}`}>
                            {actualProduct.category.category ? actualProduct.category.category : routeCategory}
                        </Link>
                        <Link component={RouterLink} color="inherit" to={`/produtos/${routeCategory}/${routeSubcategory}`}>
                            {actualProduct.category.subcategory ? actualProduct.category.subcategory : routeSubcategory}
                        </Link>
                    </Breadcrumbs>
                </div>
                <Paper className={classes.paper}>
                    <Typography variant="h1" color="textPrimary" className={classes.HeadingStyle}>{actualProduct.name}</Typography>
                    <Grid container>

                        <Grid item md={8} xs={12}>
                            <GalleryNav gallery={actualProduct.productImages} mainImg={actualProduct.productMainImg} isNew={actualProduct.isNew} style={{ marginBottom: '2rem' }} />
                        </Grid>
                        <Grid item md={4} xs={12} style={{ marginBottom: '2rem' }}>
                            <div style={{ marginBottom: '1rem', marginTop: 20 }}>
                                <Typography variant="body1" color="textPrimary" className={classes.subTitle}>Por: <span className={classes.HeadingStyle}> {actualProduct.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></Typography>
                                <Alert icon={false} severity="success" className={actualProduct.stock > 0 ? '' : classes.esgotado}>
                                    {actualProduct.stock > 0 ? 'Em estoque' : 'Esgotado'}
                                </Alert>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">Quantidade</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={quantity}
                                        onChange={handleChange}
                                    >
                                        {actualProduct.stock < 1 ? <MenuItem value={1}><em>Indisponivel</em></MenuItem> :
                                            createMenuQTD(actualProduct.stock)
                                        }
                                    </Select>
                                </FormControl>

                                <Button variant="contained"
                                    style={{ backgroundColor: green[400], color: '#fff', marginTop: '1rem' }}
                                    size="large"
                                    fullWidth={true}
                                    onClick={() => addToBasket()}
                                    startIcon={<AddShoppingCartIcon />}
                                >
                                    <span>Adicionar ao orçamento</span>
                                </Button>
                            </div>
                            <Table size="small" aria-label="formas de pagamento" className="pagamento">
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={4} style={{ fontSize: '0.875rem' }}>Formas de pagamento</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" colSpan={3} scope="row"> Dinheiro / Transferência / Pix </TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Cartão 1x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.041).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        <TableCell component="th" scope="row">Cartão 7x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.11 / 7).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Cartão 2x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.05 / 2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        <TableCell component="th" scope="row">Cartão 8x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.13 / 8).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Cartão 3x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value / 3 * 1.05).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        <TableCell component="th" scope="row">Cartão 9x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.17 / 9).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Cartão 4x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value / 4 * 1.06).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        <TableCell component="th" scope="row">Cartão 10x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.18 / 10).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Cartão 5x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value / 5 * 1.1).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        <TableCell component="th" scope="row">Cartão 11x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.19 / 11).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">Cartão 6x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.1 / 6).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        <TableCell component="th" scope="row">Cartão 12x</TableCell>
                                        <TableCell align="right">{(quantity * actualProduct.value * 1.2 / 12).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                            <div style={{ marginTop: 20 }}>
                                {/* <Typography variant="h6">Calcular frete:</Typography> */}
                                <div style={{ margin: '.6rem 0px' }}>
                                    <FetchCep setCepData={setCEPData} hasButton={true} />
                                </div>
                                <div style={cepValue === 0 ? { display: 'none' } : { display: 'block' }}>
                                    <Typography variant="h6">Valor do frete: {cepValue > 0 ? cepValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "à combinar"}</Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <div dangerouslySetInnerHTML={createMarkup()} />
                </Paper>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
                            Produtos Relacionados
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ProductCarousel products={relatedProds} displayGrid={false} />
                    </Grid>
                </Grid>
            </Container>
            <Backdrop className={classes.backdrop} open={backdropOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}


export default ProductPage;