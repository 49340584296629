
import { useState, useEffect } from "react";
import { firestore } from "../firebase";
import firebase from 'firebase';

export default function useFetchCart() {
  
    //fetch products
    const [cartRef, setCartRef] = useState(localStorage.getItem('@uaieconomizei/cartRef'));
    const [cart, setCart] = useState([])

    useEffect(() => {
        if (cartRef) {
            return firestore.collection('basket').doc(cartRef).onSnapshot(
                (snapshot) => {

                    setCart(snapshot.data());
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            firestore.collection('basket').add({
                products: [],
                createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
                updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
            }).then(ref => {
                console.log('Added document with ID: ', ref.id);
                localStorage.setItem('@uaieconomizei/cartRef', ref.id);
                setCartRef(localStorage.getItem('@uaieconomizei/cartRef'))
            });
        }
    }, [cartRef]);

    return cart;
}