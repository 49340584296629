import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import { Link } from "react-router-dom";

import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        backgroundColor: red[600],
        color: 'white',
        transition: 'all 250ms ease-in-out',
        '& span': {
            fontSize: '1rem',
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        "&:hover": {
            backgroundColor: red[500],
            opacity: 0.8,
        },
    }
}));

export default function VerticalMenu(props) {
    const classes = useStyles();

    const categories = props.categories;

    const listagemCat = categories.map((categoryObj) => {

        if (categoryObj.subcategory) {
            var slugCategory = categoryObj.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
            return (
                <React.Fragment key={categoryObj.title}>
                    <ListItem button component={Link} to={`/produtos/${slugCategory}`} className={classes.title}>
                        <ListItemText primary={categoryObj.title} />
                    </ListItem>
                    <Collapse in={true}>
                        <List component="div" disablePadding dense={true}>
                            {categoryObj.subcategory.map((subcat,index) => {
                                var slugSubategory = subcat.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                                if (subcat.title) {
                                    return (
                                        <ListItem button key={subcat.title} component={Link} to={`/produtos/${slugCategory}/${slugSubategory}`} className={classes.nested}>
                                            <ListItemText primary={subcat.title} />
                                        </ListItem>
                                    )
                                }else{
                                    return(<React.Fragment key={index}></React.Fragment>)
                                }
                            }
                            )}
                        </List>
                    </Collapse>
                </React.Fragment>
            )
        } else {
            return (
                <ListItem button component={Link} to={`/produtos/${slugCategory}`} className={classes.title}>
                    <ListItemText primary={categoryObj.title} />
                </ListItem>
            )
        }
    }
    )

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
            dense={true}
        >
            {listagemCat}
        </List>
    );
}
