
import { useState, useEffect } from "react";
import { firestore } from "../firebase";


export default function useFetchProducts() {    
    //fetch categories
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        return firestore.collection('categories').onSnapshot(
            (snapshot) => {
                const listCategories = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setCategories(listCategories);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);

    return categories;
}