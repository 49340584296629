import React, { useState, useEffect } from "react";
import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

import {
    useParams,
} from "react-router-dom";

import {
    Hidden,
    Grid,
} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import BackToTOp from '../../components/ScrollBackToTop';
import VerticalMenu from "../home/VerticalMenu";
// import Footer from "../../components/Footer/Footer.js";
import ProductCards from "../../components/ProductCards/ProductCards.js";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
    },
}));


export default function ProductSubcategory(props) {

    const classes = useStyles();

    const { routeCategory, routeSubcategory } = useParams()

    //fetch products
    const products = props.products;
    const categories = props.categories;

    const [category, setCategory] = useState('');
    const [subcategory, setSubcategory] = useState('');

    const filteredProds = products.filter(item => item.category.subcategory.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase() === routeSubcategory)

    useEffect(() => {
        filteredProds.forEach(prod => {
            setCategory(prod.category.category)
            setSubcategory(prod.category.subcategory)
        });
    }, [filteredProds]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{category} - {subcategory} - Uai Economizei</title>
                <meta property="og:type" content="website" />
                <meta property="og:description" content={`Listagem de produtos na seção de ${subcategory}`} />
                <meta property="description" content={`Listagem de produtos na seção de ${subcategory}`} />
            </Helmet>
            <CssBaseline />
            <Toolbar id="back-to-top-anchor" style={{ minHeight: 0 }} />
            <Container maxWidth="lg" disableGutters={true} style={{ marginBottom: '4rem', minHeight: '100vh' }}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h4' style={{ margin: '1rem 0' }}>{category ? category : routeCategory} - {subcategory ? subcategory : routeSubcategory}</Typography>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Hidden mdDown>
                            <VerticalMenu categories={categories} />
                        </Hidden>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Grid container spacing={2} justify="center">
                            <ProductCards products={filteredProds} />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
