import React, { useEffect, useState } from 'react';

import Helmet from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';

import { firestore } from "../../firebase";
import firebase from 'firebase';
import emailjs from 'emailjs-com';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from "@material-ui/core/Grid";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';
import HomeIcon from '@material-ui/icons/Home';

// sections
import CartStep from './sections/CartStep';
import IdentityStep from './sections/IdentityStep';
import ConfirmStep from './sections/ConfirmStep';


import scrollToTopFunction from '../../util/ScrollToTopFunction';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        margin: 'auto',
        width: '100%',
    },
    table: {
        minWidth: 650,
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));


export default function OrderPage(props) {
    const classes = useStyles();
    const cart = props.cart;
    const user = props.user;
    const userData = props.userData;

    const { openSnackbar } = props;
    const { onSignInClick } = props;
    const { onSignUpClick } = props;

    const [activeStep, setActiveStep] = useState(0);


    // const [orderRef, serOrderRef] = useState('');
    const [order, setOrder] = useState([])
    const [totalProducts, setTotalProducts] = useState(0)
    const [disableNext, setDisableNext] = useState(true)
    const steps = ['Orçamento', 'Identificação', 'Confirmar Solicitação'];

    const dbOrders = firestore.collection('orders');
    const dbBasket = firestore.collection('basket');

    useEffect(() => {
        if (cart) {
            if (cart.products) {
                if (activeStep === 0 && cart.products.length > 0) {
                    setDisableNext(false)
                }
                else if (activeStep === 1 && user) {
                    if (userData.address.length > 0) {
                        setDisableNext(false)
                    } else {
                        setDisableNext(true)
                    }

                }
                //  else if (activeStep === 2) {
                //     console.log(order)
                //     setDisableNext(false)
                // } 
                else {
                    setDisableNext(true)
                }
            } else { setDisableNext(true) }
        } else { setDisableNext(true) }

    }, [activeStep, cart, user, userData])


    const handleNextStep = () => {
        if (activeStep === steps.length - 1) {
            placeOrder()
            console.log('pedido finalizado')
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        scrollToTopFunction()
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        scrollToTopFunction()
    };


    function sendEmail() {
        if (userData) {
            var templateParams = {
                from_name: userData.firstName,
            }

            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_USER_ID)
                .then((result) => {
                    console.log('Email enviado com sucesso!');
                    console.log(result)
                }, (error) => {
                    console.log(error.text);

                    console.log('Erro ao enviar email')
                });
        }

    }

    //ao realizar o pedido
    const placeOrder = () => {
        dbOrders.add({
            order: order,
            status: 0,
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }).then(ref => {
            console.log(ref.id)
            sendEmail()
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            clearBasket();
        });
    }

    const clearBasket = () => {
        dbBasket.doc(localStorage.getItem('@uaieconomizei/cartRef')).update({
            products: [],
            createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }).then(ref => {

        });
    }

    const getSetpsContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <CartStep
                    cart={cart}
                    totalProducts={totalProducts}
                    setTotalProducts={setTotalProducts}
                />;
            case 1:
                return <IdentityStep
                    openSnackbar={openSnackbar}
                    onSignUpClick={onSignUpClick}
                    onSignInClick={onSignInClick}
                    user={user}
                    userData={userData}
                />;
            case 2:
                return <ConfirmStep
                    cart={cart}
                    user={user}
                    order={order}
                    totalProducts={totalProducts}
                    setDisableNext={setDisableNext}
                    setOrder={setOrder}
                    userData={userData} />;
            default:
                return 'Unknown stepIndex';
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Orçamento - Uai Economizei </title>
            </Helmet>
            <CssBaseline />
            <Toolbar id="back-to-top-anchor" style={{ minHeight: 0 }} />
            <Container maxWidth="lg" disableGutters={true} style={{ marginBottom: '4rem', marginTop: '1.5rem', minHeight: '100vh' }}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <div className={classes.root}>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {steps.map((label, key) => (
                                    <Step key={key}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                {activeStep === steps.length ? (
                                    <div>
                                        <Alert severity="success" style={{ margin: "20px 0" }}>
                                            <AlertTitle style={{ fontSize: '1.4rem' }}>
                                                Sua solicitação de orçamento foi enviada!
                                                {/* O número do seu orçamento é: <b>{orderRef}</b> */}
                                            </AlertTitle>
                                            <p>O seu orçamento será analisado e em breve entraremos em contato.</p>
                                            {/* <p>Voce pode <a href='#'>visualizar o status do seu pedido aqui.</a></p> */}
                                            
                                            <Button variant="text" color="primary" component={RouterLink} to="/minha-conta">Verificar Status do Orçamento</Button>
                                            <br/>
                                            <Button variant="contained" color="primary" component={RouterLink} to="/"> <HomeIcon />Home</Button>
                                        </Alert>
                                    </div>
                                ) : (
                                    <div>
                                        <div className={classes.instructions}>{getSetpsContent(activeStep)}</div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Button
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                className={classes.backButton}
                                            >
                                                Voltar
                                                </Button>

                                            <Button variant="contained" disabled={disableNext} color="primary" onClick={handleNextStep}>
                                                {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                                            </Button>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
