import React, { useEffect, useState } from 'react'

import Helmet from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import {
    Hidden,
    Grid,
} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import VerticalMenu from "../home/VerticalMenu";
import ProductCards from "../../components/ProductCards/ProductCards.js";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
    },
}));


export default function SearchPage(params) {
    const query = params.query
    const products = params.products
    const categories = params.categories
    const [searchTerm, setSearchTerm] = useState('')
    const [filtered, setFiltered] = useState([])
    // const [filteredCategory, setFilteredCategory] = useState([])
    // const [filteredSubCategory, setFilteredSubCategory] = useState([])
    const classes = useStyles();

    const normalize = (str) => {
        return (str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replaceAll("-", " ").toLowerCase())
    }
    useEffect(() => {
        if (products && query) {
            let filtered = _.filter(products, function (item) {
                return normalize(item.name).includes(normalize(query))
            });
            // var filteredCategory = _.filter(products, function (item) {
            //     return normalize(item.category.category).includes(normalize(query))
            // });
            // var filteredSubCategory = _.filter(products, function (item) {
            //     return normalize(item.category.subcategory).includes(normalize(query))
            // });

            // var pullSubcategory = _.pullAll(filteredSubCategory, filtered, filteredCategory)
            // var pullCategory = _.pullAll(filteredCategory, filtered, filteredSubCategory)

            var sorted = _.sortBy(filtered, ['name']);
            setFiltered(sorted)
            // setFilteredCategory(pullCategory)
            // setFilteredSubCategory(pullSubcategory)

            setSearchTerm(query.replaceAll("-", " "))
        }
    }, [products, query])


    return (
        <React.Fragment>
            <Helmet>
                <title>Pesquisa {searchTerm} - Uai Economizei</title>
                <meta property="og:title" content={`Pesquisa ${searchTerm} - Uai Economizei`} />
                <meta property="og:site_name" content="Uai Economizei" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content={`Pesquisa de produtos - ${searchTerm}`} />
                <meta property="description" content={`Pesquisa de produtos - ${searchTerm}`} />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={window.location.origin + "/logo192.png"} />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="55" />
                <meta property="og:image:height" content="47" />
            </Helmet>
            <CssBaseline />
            <Toolbar id="back-to-top-anchor" style={{ minHeight: 0 }} />
            <Container maxWidth="lg" disableGutters={true} style={{ marginBottom: '4rem', minHeight: '100vh' }}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h4' style={{ margin: '1rem 0' }} gutterBottom>Pesquisa: {searchTerm}</Typography>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                        <Hidden mdDown>
                            <VerticalMenu categories={categories} />
                        </Hidden>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Grid container spacing={2} justify="center">
                            <ProductCards products={filtered} />
                            {/* <ProductCards products={filteredSubCategory} />
                            <ProductCards products={filteredCategory} /> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )

}