import React from 'react';

// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

//sections
// import Profile from './sections/Profile';
// import Settings from './sections/Settings';
import Orders from './sections/Orders';


// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`vertical-tabpanel-${index}`}
//             aria-labelledby={`vertical-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box p={3}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.any.isRequired,
//     value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `vertical-tab-${index}`,
//         'aria-controls': `vertical-tabpanel-${index}`,
//     };
// }

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '4rem auto',
        minHeight: '100vh',
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(1),
    },
    tabsContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginTop: '1.3rem',
        marginBottom: '1.3rem',
        '& .MuiTabs-root ': {
            borderRight: 'none',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '2rem',
            marginBottom: '2rem',
            display: 'flex',
            '& .MuiTabs-root ': {
                borderRight: '1px solid rgba(0, 0, 0, 0.12)'
            }
        }

    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },

}));


function UserPage(props) {
    const classes = useStyles();
    const { user } = props
    // const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.up('md'));

    // const [value, setValue] = React.useState(0);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    return (
        <React.Fragment>
            <Helmet>
                <title>Minha conta - Uai Economizei</title>
                <meta property="og:type" content="website" />
                <meta property="og:description" content='' />
                <meta property="description" content='' />
            </Helmet>

            <Container maxWidth="lg" disableGutters={true} className={classes.root}>
                <Paper className={classes.paper}>
                    <Typography variant="h5" gutterBottom>
                        Área do cliente
                    </Typography>
                    <Typography variant="h6" component="h6" color="primary" gutterBottom style={{ margin: '25px 10px' }}>
                        Orçamentos
                    </Typography>
                    <Orders user={user}/>
                    {/* <div className={classes.tabsContainer}>
                       
                    </div> */}
                </Paper>
            </Container>
        </React.Fragment>
    )
}

export default UserPage;