import React from "react";
import Slider from "react-slick";

import { Link } from "react-router-dom";
import {
    Typography,
    Card,
    CardActionArea,
    // CardActions,
    CardContent,
    Button,
} from "@material-ui/core";
// import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <Button color="primary" onClick={onClick} style={{ minWidth: '45px' }}>
            <NavigateNextIcon style={{ fontSize: '2.5rem' }} />
        </Button>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <Button color="primary" onClick={onClick} style={{ minWidth: '45px' }}>
            <NavigateBeforeIcon style={{ fontSize: '2.5rem' }} />
        </Button>
    );
}


export default function ProductCarousel(props) {
    const products = props.products;

    const settingsProdCarousel = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ],
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const productCards = products.map(prod => {

        var slugCategory = prod.category.category.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
        var slugSubategory = prod.category.subcategory.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
        var slugName = prod.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();

        var nameShort;

        if (prod.name.length > 70) {
            nameShort = prod.name.substring(0, 70) + '...'
        } else {
            nameShort = prod.name
        }


        return (
            <div key={prod.id}>
                <div style={{ padding: '0 8px' }}>
                    <Card>
                        <CardActionArea component={Link}
                            to={`/produtos/${slugCategory}/${slugSubategory}/${slugName}?id=${prod.id}`}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '150px' }}>
                                <img
                                    style={{ maxHeight: "150px", maxWidth: "150px" }}
                                    src={prod.productMainImg.url}
                                    alt={prod.name}
                                    loading="lazy"
                                />
                            </div>
                            <CardContent style={{ minHeight: "150px", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <Typography variant="subtitle1" color="textPrimary" style={{ fontSize: '.9rem' }} >{nameShort}</Typography >
                                <Typography variant="subtitle2" color="textPrimary" style={{ fontWeight: "bold", fontSize: "1.5rem" }} >{prod.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </div>
            </div>
        )

    })

    return (
        <div>
            <Slider {...settingsProdCarousel} style={{ display: 'flex' }} >
                {productCards}
            </Slider>
        </div>
    );
}




