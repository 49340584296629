import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import FetchCep from '../../../util/FetchCep';

import { firestore } from "../../../firebase";
import firebase from "firebase";

const useStyles = makeStyles({
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.5rem',
        margin: '.5rem 0',
        '& svg': {
            fontSize: '2rem',
            marginRight: '.5rem'
        }
    },
});

export default function AddressForm(props) {
    const classes = useStyles();
    const userId = props.userId;
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cepData, setCEPData] = useState({
        cep: '',
        uf: '',
        logradouro: '',
        bairro: '',
        localidade: '',
        value: ''
    })
    const [address, setAddress] = useState({
        cep: "",
        bairro: "",
        logradouro: "",
        localidade: "",
        uf: "",
        numero: "",
        complemento: "",
    })
    const { onClose, open } = props;
    const [errorFetchCEP, setErrorFetchCEP] = useState(false)
    const [freeForm, setFreeForm] = useState(false)
    
    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        setAddress({
            cep: cepData.cep,
            bairro: cepData.bairro,
            logradouro: cepData.logradouro,
            localidade: cepData.localidade,
            uf: cepData.uf,
            numero: numero,
            complemento: complemento
        });
        // console.log(cepData)
    }, [cepData, numero, complemento])

  
    const addAdrressUser = async () => {
        if (address.cep !== "" && userId) {
            await firestore.collection('users').doc(userId).update({ address: firebase.firestore.FieldValue.arrayUnion(address) }).then(ref => {
                setNumero('')
                setComplemento('')
                handleClose()
            })
        } else {
            alert('informe o CEP para cadastrar o endereço.')
        }
    }


    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Seu endereço:</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {errorFetchCEP ?
                        <Grid item xs={12}>
                            <Button variant="text" onClick={e => setFreeForm(true)}>
                                Meu CEP não foi encontrado
                            </Button>
                        </Grid>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    {!freeForm ?
                        <React.Fragment>
                            <Grid item md={4} sm={6} xs={12}>
                                <FetchCep setCepData={setCEPData} setErrorFetchCEP={setErrorFetchCEP} hasButton={false} />
                            </Grid>
                            <Grid item md={8} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Logradouro" disabled style={{ backgroundColor: "#e9ecef" }} className={classes.disabled} value={cepData.logradouro || ''} />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Número" required value={numero} onChange={e => setNumero(e.target.value)} />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Complemento" value={complemento} onChange={e => setComplemento(e.target.value)} />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Bairro" disabled style={{ backgroundColor: "#e9ecef" }} className={classes.disabled} value={cepData.bairro || ''} />
                            </Grid>
                            <Grid item md={9} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Cidade" disabled style={{ backgroundColor: "#e9ecef" }} className={classes.disabled} value={cepData.localidade || ''} />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="UF" disabled style={{ backgroundColor: "#e9ecef" }} className={classes.disabled} value={cepData.uf || ''} />
                            </Grid>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="CEP" value={cepData.cep} onChange={e => setCEPData({ ...cepData, cep: e.target.value })} />
                            </Grid>
                            <Grid item md={8} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Logradouro" value={cepData.logradouro} onChange={e => setCEPData({ ...cepData, logradouro: e.target.value })} />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Número" required value={numero} onChange={e => setNumero(e.target.value)} />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Complemento" value={complemento} onChange={e => setComplemento(e.target.value)} />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Bairro" value={cepData.bairro} onChange={e => setCEPData({ ...cepData, bairro: e.target.value })} />
                            </Grid>
                            <Grid item md={9} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="Cidade" value={cepData.localidade} onChange={e => setCEPData({ ...cepData, localidade: e.target.value })} />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <TextField variant="outlined" fullWidth label="UF" value={cepData.uf} onChange={e => setCEPData({ ...cepData, uf: e.target.value })} />
                            </Grid>
                        </React.Fragment>
                    }

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                    </Button>
                <Button onClick={addAdrressUser} color="primary">
                    Cadastrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

