import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { firestore } from "../../../firebase";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



export default function AddressForm(props) {
    const userId = props.userId;
    const { onClose, open } = props;


    const [dadosUser, setDadosUser] = useState({
        firstName: '',
        emailAddress: '',
        phone: ''
    })

    //snackbar
    const [openSnack, setOpenSnack] = useState(false);

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    useEffect(() => {
        if (userId) {
            return firestore.collection('users').doc(userId).onSnapshot(
                (snapshot) => {
                    setDadosUser({
                        firstName: snapshot.data().firstName,
                        emailAddress: snapshot.data().emailAddress,
                        phone: snapshot.data().phone
                    })
                },
                (error) => {
                    console.log(error);
                }
            );
        }

    }, [userId]);

    const handleClose = () => {
        onClose();
    };

    function updateUser() {
        console.log(dadosUser)
        if (userId) {
            firestore.collection('users').doc(userId).update({
                firstName: dadosUser.firstName,
                emailAddress: dadosUser.emailAddress,
                phone: dadosUser.phone
            }).then(ref => {
                console.log("atualizado");
                setOpenSnack(true);
            });

        }
        onClose();
    }


    return (
        <>
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Seus dados:</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth label="Nome" required value={dadosUser.firstName} onChange={e => setDadosUser({ ...dadosUser, firstName: e.target.value })} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth label="Email" value={dadosUser.emailAddress} onChange={e => setDadosUser({ ...dadosUser, emailAddress: e.target.value })} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth label="Telefone" value={dadosUser.phone} onChange={e => setDadosUser({ ...dadosUser, phone: e.target.value })} />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={updateUser} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success">
                    Os seus dados foram atualizados!
                </Alert>
            </Snackbar>
        </>
    )
}

