import React, { useState } from 'react';
import ViaCep from 'react-via-cep';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { CalcFrete } from '../util/CalcFrete';
import useFetchCEP from '../data/useFetchCEP';


const useStyles = makeStyles((theme) => ({
    inputGroupInput: {
        '& .MuiOutlinedInput-root': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
        }
    },
    inputGroup: {
        display: 'flex',
        flexDirection: 'row',
    }
}))

function FetchCep(props) {
    const classes = useStyles();
    const { setCepData } = props;
    const { hasButton } = props;
    const { setErrorFetchCEP } = props;
    const cepList = useFetchCEP();

    const [cep, setCep] = useState('')

    const handleChangeCep = (evt) => {
        setCep(evt.target.value)
    }

    const handleSuccess = (cepData) => {
        let _cep = String(cepData.cep)
        let actualCEP = _cep.replace('-', '')
        let CepObj = CalcFrete(cepList, Number(actualCEP))
        let testeObj = {
            ...cepData,
            valor: CepObj.valor
        }
        setCepData(testeObj)

    }

    return (
        <React.Fragment>
            <ViaCep cep={cep} onSuccess={handleSuccess} lazy>
                {({ data, loading, error, fetch }) => {
                    if (error && setErrorFetchCEP) {
                        setErrorFetchCEP(true)
                    }
                    return (
                        <React.Fragment>
                            {hasButton ?
                                <div className={classes.inputGroup}>
                                    <TextField variant="outlined" className={classes.inputGroupInput} label="Digite o seu CEP" required size="small" onChange={handleChangeCep} error={error} helperText={error ? "CEP não encontrado" : ""} disabled={loading} value={cep} placeholder="00000-000" type="text" inputProps={{ maxLength: 9 }} />
                                    <Button variant="contained" disableElevation color="primary" onClick={fetch} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, maxHeight: 40 }}>Calcular</Button>
                                </div>
                                :
                                <TextField variant="outlined" required label="Digite o seu CEP" onChange={handleChangeCep} onBlur={fetch} error={error} helperText={error ? "CEP não encontrado" : ""} disabled={loading} value={cep} placeholder="00000-000" type="text" inputProps={{ maxLength: 9 }} style={{ width: '100%' }} />
                            }
                        </React.Fragment>
                    )


                    // if (loading) {
                    //     setError(false)
                    //     return <Button disabled variant="contained" color="primary" onClick={fetch}>Pesquisar</Button>
                    // }
                    // if (error) {
                    //     setError(true);
                    //     return <Button variant="contained" color="primary" onClick={fetch}>Pesquisar</Button>
                    // }
                    // if (data) {
                    //     setError(false)
                    //     return <Button variant="contained" color="primary" onClick={fetch}>Pesquisar</Button>
                    // }
                    // return <Button variant="contained" color="primary" onClick={fetch}>Pesquisar</Button>
                }}
            </ViaCep>
        </React.Fragment >
    );
}

export default FetchCep;
