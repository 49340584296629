import React, { useState } from "react";
import Slider from "react-slick";
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import './ProductImages.css';

function SampleNextArrow(props) {
    const { style, onClick } = props;
    return (
        <Button color="primary" onClick={onClick} style={{ ...style, minWidth: '45px', position: 'absolute', top: 0, right: 0, height:'100%', lineHeight:0  }}>
            <NavigateNextIcon />
        </Button>
    );
}

function SamplePrevArrow(props) {
    const { style, onClick } = props;
    return (
        <Button color="primary" onClick={onClick} style={{ ...style, minWidth: '45px', position: 'absolute', top: 0, left: 0, zIndex: 1000, height:'100%', lineHeight:0 }}>
            <NavigateBeforeIcon />
        </Button>
    );
}



export default function GalleryNav(props) {
    const gallery = props.gallery;
    const mainImg = props.mainImg;
    const isNew = props.isNew;

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);


    const productGalleryPhotos = gallery.map((item,key) => {
        // var size = Object.keys(gallery).length;
        return (
            <div key={key}>
                <div className='img-container'>
                    <img src={item.url} className='slick-img' alt={item.name}/>
                </div>
            </div>
        )
    });

    const firstImg = (
        <div data-index="0" className="slick-slide">
            <div className='img-container'>
                <img src={mainImg.url} className='slick-img' alt={mainImg.name} />
            </div>
        </div>
    );

    return (
        <div className='carousel-containter'>
            <div style={{ position: 'relative' }}>
                <Slider
                    asNavFor={nav2}
                    ref={slider => (setNav1(slider))}
                    lazyLoad={true}
                    arrows={false}
                    infinite={false}
                    className='carousel-prod'
                >
                    {firstImg}
                    {productGalleryPhotos}
                </Slider>
                <Alert severity={`${isNew === 'novo' ? 'success' : 'warning'}`} icon={false} variant="filled" className='alert-is-new'>{isNew}</Alert>
            </div>

            <Slider
                asNavFor={nav1}
                ref={slider => (setNav2(slider))}
                slidesToShow={4}
                infinite={false}
                arrows={true}
                lazyLoad={true}
                focusOnSelect={true}
                nextArrow={<SampleNextArrow />}
                prevArrow={<SamplePrevArrow />}
                className='carousel-nav'
            >
                {firstImg}
                {productGalleryPhotos}
            </Slider>
        </div>
    );

}