import React, { useEffect, useState } from 'react';
import { firestore } from '../../../firebase';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { Button, TableContainer, Table, TableRow, TableBody, TableCell, Grid, TableHead, LinearProgress } from '@material-ui/core';
import FormatBRL from '../../../util/FortmatBRL';
import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({

    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));


function Orders(props) {
    const { user } = props;
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [orders, setOrders] = useState([])

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = (itemName) => {
        setExpanded({ [itemName]: !expanded[itemName] });
    };

    const statusOptions = ['Em Análise', 'Concluído', 'Cancelado']
    const statusColor = ['textSecondary', 'primary', 'secondary']

    useEffect(() => {
        // return firestore.collection('orders').orderBy("createdAt", "desc").onSnapshot(
        return firestore.collection('orders').where('order.user.uid', '==', user.uid).orderBy("createdAt", "desc").onSnapshot(
            (snapshot) => {
                const listOrders = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setLoading(false);
                setOrders(listOrders);
                // console.log(listOrders)
            },
            (error) => {
                setLoading(false);
                console.error(error)
                setError(true);
            }
        );
    }, [user.uid]);

    const trataCEPValue = (cepValue) => {
        if (cepValue === 1e-13) {
            return 0
        } else {
            return cepValue
        }

    }

    if (loading) {
        return (
            <div style={{ minHeight: '20vh' }}>
                <LinearProgress />
            </div>)
    } else {
        if (error) {
            return (
                <div style={{ minHeight: '20vh' }}>
                    <Alert severity="error">Houve um erro ao carregar os orçamentos.</Alert>
                </div>)
        } else {
            if (!orders.length) {
                return (
                    <div style={{ minHeight: '20vh' }}>
                        <Alert severity="info">Você ainda não fez nenhum orçamento.</Alert>
                    </div>
                )
            }
            return (
                <div style={{ minHeight: '20vh' }}>
                    <Grid container spacing={2}>
                        {orders.map((item, key) => (
                            <Grid item xs={12} sm={6} key={key}>
                                <Card>
                                    <CardContent style={{ paddingBottom: 0 }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography variant="h6" color="primary" style={{ fontSize: '1rem' }}>
                                                Pedido - {item.id}
                                            </Typography>
                                            <Typography variant="subtitle1" style={{ fontSize: '0.85rem', color: 'gray', display: 'flex', alignItems: 'center' }}>
                                                <TodayOutlinedIcon fontSize="small" style={{ marginLeft: 8 }} /> {item.createdAt.toDate().toLocaleDateString()}
                                            </Typography>
                                        </div>
                                        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                            <Typography variant="body2" color={statusColor[item.status]} component="p">
                                                Status: {statusOptions[item.status]}
                                            </Typography>
                                        </div>

                                    </CardContent>
                                    <CardActions disableSpacing>
                                        <Button onClick={() => handleExpandClick(item.id)}>
                                            Ver detalhamento
                                    </Button>
                                        <IconButton
                                            className={clsx(classes.expand, {
                                                [classes.expandOpen]: expanded[item.id],
                                            })}
                                            onClick={() => handleExpandClick(item.id)}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                            color="primary"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </CardActions>
                                    <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
                                        <CardContent>
                                            <TableContainer >
                                                <Table className={classes.table} size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Produto</TableCell>
                                                            <TableCell>Qtd.</TableCell>
                                                            <TableCell>Preço</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {item.order.cart.products.map((item, key) => (
                                                            <TableRow>
                                                                <TableCell>
                                                                    {item.name}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.quantity}x
                                                            </TableCell>
                                                                <TableCell>
                                                                    {FormatBRL(item.value)}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <TableRow>
                                                            <TableCell><b>Frete</b></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell>
                                                                {item.order.retiradaBalcao ?
                                                                    <>Retirada</>
                                                                    :
                                                                    <>
                                                                        {item.order.cepValue === 0 ? <div style={{ color: 'red', width:72 }}>à combinar</div> : FormatBRL(trataCEPValue(item.order.cepValue))}
                                                                    </>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell><b>Total</b></TableCell>
                                                            <TableCell></TableCell>
                                                            <TableCell>
                                                                {FormatBRL(item.order.totalProducts + trataCEPValue(item.order.cepValue))}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Collapse>
                                </Card>
                            </Grid>
                        ))}

                    </Grid>

                </div>
            )
        }


    }

}

export default Orders;