import React from "react";
import Helmet from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';

import {
    Hidden,
    Grid,
} from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
// import BackToTOp from '../../components/ScrollBackToTop';
// import Footer from "../../components/Footer/Footer.js";

import VerticalMenu from "./VerticalMenu";
import Carousel from "./Carousel";
import ProductCards from "../../components/ProductCards/ProductCards.js";



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        margin: 'auto'
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        marginBottom: '4rem',
        minHeight: '100vh',
    }
}));


export default function Homepage(props) {

    const classes = useStyles();

    //fetch products
    const products = props.products;
    const categories = props.categories;

    return (
        <React.Fragment>
            <Helmet>
                <htmlAttributes />
                <title>Uai Economizei</title>
                <meta property="description" content="Na Uai Economizei você encontra o seu produto com o melhor preço. Atendemos Belo Horizonte e região." />
                <meta property="og:title" content="Uai Economizei" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Na Uai Economizei você encontra o seu produto com o melhor preço. Atendemos Belo Horizonte e região." />
                <meta property="og:locale" content="pt_BR" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={window.location.origin + "/logo192.png"} />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:image:width" content="55" />
                <meta property="og:image:height" content="47" />
                <link rel="canonical" href={window.location.href} />

            </Helmet>
            <CssBaseline />
            <Toolbar id="back-to-top-anchor" style={{ minHeight: 0 }} />
            <Container maxWidth="lg" disableGutters={true} className={classes.container}>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <Carousel />
                    </Grid>
                </Grid>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item lg={3} xs={12}>
                        <Hidden mdDown>
                            <VerticalMenu categories={categories} />
                        </Hidden>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <Grid container spacing={2} justify="center">
                            <ProductCards products={products} />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
