import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebase';
import firebase from 'firebase';

import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

// import ViaCep from 'react-via-cep';

import FormatBRL from '../../../util/FortmatBRL';

import FetchCep from '../../../util/FetchCep';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles({
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.5rem',
        margin: '.5rem 0',
        '& svg': {
            fontSize: '2rem',
            marginRight: '.5rem'
        }
    },
    PaymentOptions: {
        fontSize: '1.1rem',
        display: 'block',
        textAlign: 'center',
        margin: '30px 0',
    }
});

export default function CartStep(props) {
    const classes = useStyles();
    const cart = props.cart["products"];
    const [ totalProducts, setTotalProducts ] = useState(0)
    const [cepValue, setCepValue] = useState(0)

    const [cartProducts, setCartProducts] = useState([]);
    const [cartRef, setCartRef] = useState(localStorage.getItem('@uaieconomizei/cartRef'))

    useEffect(() => {
        if (cart) {
            setCartProducts(cart) 
            const total = cart.reduce(
                (prevValue, currentValue) => prevValue + (currentValue.value * currentValue.quantity), 0
            );
            setTotalProducts(total)
            setCartRef(localStorage.getItem('@uaieconomizei/cartRef'))
        }
    }, [cart]);

    const increaseUnit = (_rowIndex) => {
        var baskeItems = cartProducts.map((prod) => {
            if (prod.id === _rowIndex) {
                return ({
                    ...prod,
                    quantity: prod.quantity + 1
                })
            } else {
                return ({ ...prod })
            }
        })
        firestore.collection('basket').doc(localStorage.getItem('@uaieconomizei/cartRef')).update({
            products: baskeItems,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }).then(ref => {
            console.log('Atualizado!');
        });
    }

    const decreaseUnit = (_rowIndex) => {
        var baskeItems = cartProducts.map((prod) => {
            if (prod.id === _rowIndex) {
                return ({
                    ...prod,
                    quantity: prod.quantity - 1
                })
            } else {
                return ({ ...prod })
            }
        })
        firestore.collection('basket').doc(localStorage.getItem('@uaieconomizei/cartRef')).update({
            products: baskeItems,
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }).then(ref => {
            console.log('Atualizado!');
        });
    };

    const removeProductOnCart = (prodDel) => {
        firestore.collection('basket').doc(cartRef).update({
            products: firebase.firestore.FieldValue.arrayRemove(prodDel),
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
        }).then(ref => {
            console.log('Item deleted');
        });
    }

    const [cepData, setCEPData] = useState({
        cep: '',
        uf: '',
        logradouro: '',
        bairro: '',
        localidade: '',
        valor: ''
    })

    useEffect(() => {
        if (cepData.valor !== "") { 
            setCepValue(cepData.valor)
        } 
    }, [cepData])

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant='h3' className={classes.title} color='primary'> <ShoppingBasketIcon /> Produtos adicionados ao orçamento</Typography>
                            <TableContainer >
                                <Table className={classes.table} aria-label="cart table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ minWidth: '250px' }}>Produto</TableCell>
                                            <TableCell align="left">Quantidade</TableCell>
                                            <TableCell align="right">Preço</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {cartProducts.length === 0 ? <TableRow><TableCell colSpan="4" align="center" scope="row">Adicione produtos ao orçamento.</TableCell></TableRow> :

                                            Object.keys(cartProducts).map((row, i) => {

                                                var slugCategory = cartProducts[row].category.category.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                                                var slugSubategory = cartProducts[row].category.subcategory.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                                                var slugName = cartProducts[row].name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();

                                                var totalProd = cartProducts[row].quantity * cartProducts[row].value;

                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell component="th" scope="row">
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                <div style={{ textAlign: 'center', minWidth: '70px' }}>
                                                                    <img src={cartProducts[row].productMainImg.url} alt={cartProducts[row].name} style={{ maxHeight: '50px', maxWidth: '50px' }} />
                                                                </div>
                                                                <div>
                                                                    <Link component={RouterLink} color="primary" to={`/produtos/${slugCategory}/${slugSubategory}/${slugName}?id=${cartProducts[row].id}`}>
                                                                        {cartProducts[row].name}
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100px' }}>
                                                                <IconButton size="small" onClick={() => increaseUnit(cartProducts[row].id)} disabled={cartProducts[row].quantity >= cartProducts[row].stock}>
                                                                    <AddIcon fontSize="inherit" />
                                                                </IconButton>
                                                                {cartProducts[row].quantity}
                                                                <IconButton size="small" onClick={() => decreaseUnit(cartProducts[row].id)} disabled={cartProducts[row].quantity <= 1}>
                                                                    <RemoveIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="right">{FormatBRL(totalProd)}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton aria-label="delete" onClick={() => removeProductOnCart(cartProducts[row])}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant='h3' className={classes.title} color='primary'><LocalShippingIcon />Calcular Frete:</Typography>
                            <FetchCep setCepData={setCEPData} hasButton={true} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {totalProducts ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant='h3' className={classes.title} color='primary'><MonetizationOnIcon />Valor</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Table size="small" aria-label="formas de pagamento" className="pagamento">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" colSpan={3} scope="row"> Dinheiro / Transferência / Pix </TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts))}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 1x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.041)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 7x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.11 / 7)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 2x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.05 / 2)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 8x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.13 / 8)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 3x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) / 3 * 1.05)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 9x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.17 / 9)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 4x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) / 4 * 1.06)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 10x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.18 / 10)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 5x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) / 5 * 1.1)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 11x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.19 / 11)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 6x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.1 / 6)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 12x</TableCell>
                                                    <TableCell align="right">{FormatBRL((cepValue + totalProducts) * 1.2 / 12)}</TableCell>

                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item md={6} sm={12} xs={12}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Total dos produtos: </TableCell>
                                                    <TableCell>{FormatBRL(totalProducts)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Frete:</TableCell>
                                                    <TableCell>{cepValue > 0 ? FormatBRL(cepValue) : "à combinar"}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Valor Total: </TableCell>
                                                    <TableCell>{FormatBRL(cepValue + totalProducts)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <div className={classes.PaymentOptions}>
                                            <div>
                                                <b>{FormatBRL(cepValue + totalProducts)} à vista </b>
                                            </div>
                                            <div>
                                                <b>Ou {FormatBRL((cepValue + totalProducts) * 1.2)} em até 12x de {FormatBRL((cepValue + totalProducts) * 1.2 / 12)} no cartão</b>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                :
                <React.Fragment></React.Fragment>
            }

        </React.Fragment >
    );
}
