
import { useState, useEffect } from "react";
import { firestore } from "../firebase";


export default function useFetchProducts() {
 
    //fetch products
    const [products, setProducts] = useState([]);

    useEffect(() => {
        return firestore.collection('products').where("active", ">=", true).orderBy('active', 'desc').orderBy('createdAt', 'desc').onSnapshot(
            (snapshot) => {
                const listProducts = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    quantity: 1,
                    ...doc.data()
                }))
                setProducts(listProducts);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);
 
    return products;
}