import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import { firestore } from "../../../firebase";
import firebase from 'firebase';

import AddressForm from '../forms/AddressForm'
import UserForm from '../forms/UserForm'

const useStyles = makeStyles((theme) => ({

}));

export default function IdentityStep(props) {
  const classes = useStyles();
  const user = props.user;
  const userData = props.userData;
  const { onSignInClick } = props;
  const { onSignUpClick } = props;
 
  const [open, setOpen] = useState(false);
  const [openUser, setOpenUser] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

  };

  const handleClickOpenUser = () => {
    setOpenUser(true);
  };

  const handleCloseUser = () => {
    setOpenUser(false);

  };



  const [userAddressList, setUserAddressList] = useState([]);

  useEffect(() => {
    if (user) {
      return firestore.collection('users').doc(user.uid).onSnapshot(
        (snapshot) => {
          setUserAddressList(snapshot.data().address)
        },
        (error) => {
          console.log(error);
        }
      );
    }

  }, [user]);

  const [selectedValue, setSelectedValue] = useState('radio-button-0');
  // const [selectedAddress, setSelectedAddress] = useState({})

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    // setSelectedAddress(userAddressList[event.target.value.slice(-1)])
  };

 
  function deleteAddress(item) {
    if (user) {
      if (user.uid) {
        firestore.collection('users').doc(user.uid).update({
          address: firebase.firestore.FieldValue.arrayRemove(item)
        }).then(ref => {
          console.log('endereço deletado')
        });
      }
    }
  }


  return (
    <>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Card>
            <CardContent>

              <Typography variant='h3' style={{ fontSize: '1.5rem', margin: '1rem 0' }}>Identificação</Typography>
              {user && userData ?
                <Grid container justify="space-between">
                  <Grid item xs={12} sm={12} md={3} style={{ marginBottom: "1rem" }}>

                    <Card variant="outlined" >
                      <CardContent className={classes.cardContent}>
                        <div>
                          Email: {user.email}
                        </div>
                        <div>
                          Nome: {userData.firstName} {userData.lastName}
                        </div>
                        <div>
                          Telefone: {userData.phone}
                        </div>
                        <Button variant="outlined" color="primary" onClick={handleClickOpenUser} style={{ marginTop: '1rem' }}>
                          Alterar dados
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Hidden smDown>
                    <Divider orientation="vertical" flexItem />
                  </Hidden>

                  <Grid item xs={12} sm={12} md={8}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography variant='h3' style={{ fontSize: '1.5rem', margin: '1rem 0' }}>Endereços:</Typography>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{ marginBottom: '1rem' }}>
                          Cadastrar endereço
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      {userAddressList ?
                        userAddressList.map((item, key) => (
                          <Grid item xs={12} sm={6} md={6}>
                            <Card key={key} variant="outlined" style={{ height: '100%' }}>
                              <CardContent className={classes.cardContent}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Radio
                                      checked={selectedValue === `radio-button-${key}`}
                                      onChange={handleChange}
                                      value={`radio-button-${key}`}
                                      name={`radio-button-${key}`}
                                      inputProps={{ 'aria-label': key }}
                                    />
                                    <div>
                                      <div>{item.logradouro}, {item.numero},</div>
                                      <div>{item.bairro}, {item.localidade}</div>
                                      <div>{item.uf} - {item.cep} </div>
                                    </div>
                                  </div>
                                  <div>
                                    <IconButton aria-label="deletar endereço" color="secondary" onClick={() => deleteAddress(item)}>
                                      <DeleteOutline />
                                    </IconButton>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))
                        :
                        <></>
                      }
                    </Grid>
                  </Grid>

                </Grid>
                :
                <Grid container justify="space-around">
                  <Grid item md={5} xs={12}>
                    <Typography variant="h6" align="center">
                      Já possui cadastro?
                      </Typography>
                    <Typography variant="subtitle1" align="center">
                      Realize o login na sua conta
                      </Typography>
                    <div style={{ textAlign: 'center' }}>
                      <Button color="primary" variant="contained" onClick={onSignInClick}>Login</Button>
                    </div>
                  </Grid>
                  <Hidden mdDown>
                    <Divider orientation="vertical" flexItem />
                  </Hidden>
                  <Grid item md={5} xs={12}>
                    <Typography variant="h6" align="center">
                      Ainda não é cadastrado?
                      </Typography>
                    <Typography variant="subtitle1" align="center">
                      Realize seu cadastro.
                        </Typography>
                    <div style={{ textAlign: 'center' }}>
                      <Button color="secondary" variant="contained" onClick={onSignUpClick}>Cadastrar</Button>
                    </div>
                  </Grid>
                </Grid>
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {user ?
        <>
          <AddressForm userId={user.uid} open={open} onClose={handleClose} />

          <UserForm userId={user.uid} open={openUser} onClose={handleCloseUser} />

        </>
        :
        <></>
      }

    </>
  );
}
