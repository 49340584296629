import React, { useEffect } from "react";

import PropTypes from "prop-types";
import Helmet from 'react-helmet';

import { BrowserRouter, Switch, Route, useLocation, Redirect } from "react-router-dom";

//pages
// import AdminPage from "../AdminPage";
// import UserPage from "../UserPage";
import NotFoundPage from "../NotFoundPage";
import Homepage from '../../views/home';
import ProductPage from '../../views/product';
import ProductSubcategory from '../../views/ProductSubcategory';
import ProductCategory from '../../views/ProductCategory';
import UserPage from '../../views/User';
import SearchPage from '../../views/Search';

//components
import SearchAppBar from '../Bar/SearchAppBar';
import ScrollToTop from './ScrollToTop';
import Footer from '../Footer/Footer';
import BackToTOp from '../ScrollBackToTop';

// import PlaceOrder from '../../views/PlaceOrder';

import OrderPage from '../../views/OrderPage';

import useFetchProducts from '../../data/useFetchProducts';
import useFetchCategories from '../../data/useFetchCategories';

import useFetchCart from '../../data/useFetchCart';

import { firestore } from "../../firebase";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Router(props) {

  // Properties
  const { user, roles, userData } = props;

  // Functions
  const { openSnackbar } = props;

  const products = useFetchProducts();
  const categories = useFetchCategories();
  const cart = useFetchCart();

  const { onSignUpClick } = props;
  const { onSignInClick } = props;
  const { onSignOutClick } = props;


  // const res = await cityRef.update({capital: true});

  useEffect(() => {
    if (user && userData) {
      if (userData.verifiedUser === false && user.emailVerified) {
        // console.log(user.uid)
        async function verifyUser() {
          await firestore.collection('users').doc(user.uid).set({ verifiedUser: true }, { merge: true })
        }
        verifyUser()
        // console.log('usuário foi verificado')
      }
    }
  }, [user, userData])

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Helmet>
        <title>Uai Economizei</title>
        <meta name="title" content="Uai Economizei" />
        <meta property="description" content="Na Uai Economizei você encontra o seu produto com o melhor preço. Atendemos Belo Horizonte e região." />
        <meta property="og:title" content="Uai Economizei" />
        <meta property="og:description" content="Na Uai Economizei você encontra o seu produto com o melhor preço. Atendemos Belo Horizonte e região." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={window.location.origin + "/logo192.png"} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="55" />
        <meta property="og:image:height" content="47" />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <ScrollToTop />
      <SearchAppBar categories={categories} cart={cart} user={user} onSignUpClick={onSignUpClick} onSignInClick={onSignInClick} onSignOutClick={onSignOutClick} />

      <Switch>
        <Route path="/" exact>
          <Homepage products={products} categories={categories} />
          <Footer categories={categories} />
          <BackToTOp />
        </Route>

        <Route path="/produtos/:routeCategory/:routeSubcategory/:productName">
          <ProductPage products={products} categories={categories} cart={cart} />
          <Footer categories={categories} />
          <BackToTOp />
        </Route>

        <Route path="/produtos/:routeCategory/:routeSubcategory">
          <ProductSubcategory products={products} categories={categories} />
          <Footer categories={categories} />
          <BackToTOp />
        </Route>

        <Route path="/produtos/:routeCategory">
          <ProductCategory products={products} categories={categories} />
          <Footer categories={categories} />
          <BackToTOp />
        </Route>

        <Route path="/orcamento" exact>
          <OrderPage products={products} openSnackbar={openSnackbar} cart={cart} categories={categories} user={user} userData={userData} onSignUpClick={onSignUpClick} onSignInClick={onSignInClick} />
          <Footer categories={categories} />
          <BackToTOp />
        </Route>

        <Route path="/pesquisa" exact>
          <PesquisaPage products={products} categories={categories} />
        </Route>

        <Route path="/minha-conta">
          {user ? (
            <>
              <UserPage user={user} userData={userData} />
              <Footer categories={categories} />
              <BackToTOp />
            </>
          ) : (<Redirect to="/" />)}

        </Route>

        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );

}

function PesquisaPage(params) {
  let query = useQuery();
  return (
    <SearchPage query={query.get("q")} products={params.products} categories={params.categories} />
  )
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  // bar: PropTypes.element,

  // Functions
  // openSnackbar: PropTypes.func.isRequired,
};

export default Router;
