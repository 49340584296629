import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import { firestore } from "../../firebase";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", right: '2rem', left: 'unset' }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", left: '1rem', zIndex: 100 }}
            onClick={onClick}
        />
    );
}


export default function Homepage() {

    const [bannersData, setBannersData] = useState([]);
    const [loadingBanners, setLoadingBanners] = useState(true);

    useEffect(() => {
        setLoadingBanners(true)
        return firestore.collection('banners').orderBy("createdAt", "asc").onSnapshot((querySnapshot) => {
            var bannersData = [];
            querySnapshot.forEach((doc) => {
                bannersData.push({ id: doc.id, ...doc.data() })
                setLoadingBanners(false)
            });
            setBannersData(bannersData)
        });
    }, []);


    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed: 20000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        appendDots: dots => (
            <ul className='casousel-home-dots'> {dots} </ul>
        )
    };

    return (
        <div className='carouselHome' style={{ maxWidth: '100%', margin: 'auto' }}>
            {loadingBanners ?
                '' :
                <Slider {...settings}>
                    {bannersData.map((item, key) => (
                        <img key={key} src={item.bannerImg.url} alt={item.bannerImg.name} loading="lazy" className="slick-image" />
                    ))}
                </Slider>
            }
        </div>
    );
}