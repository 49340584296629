import React, { useState, useEffect } from 'react';


//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';
import { Alert, AlertTitle } from '@material-ui/lab';

//util
import FormatBRL from '../../../util/FortmatBRL';
import { CalcFrete } from '../../../util/CalcFrete';
import useFetchCEP from '../../../data/useFetchCEP';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.5rem',
        margin: '.5rem 0',
        '& svg': {
            fontSize: '2rem',
            marginRight: '.5rem'
        }
    },
    formasPagamento: {
        fontSize: '1.1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        margin: '30px 0'
    }
}));

export default function ConfirmStep(props) {
    const classes = useStyles();

    const { cart, user, userData, setOrder, setDisableNext } = props;

    const cepList = useFetchCEP();
    const [totalProducts, setTotalProducts] = useState(0)
    const [address, setAddress] = useState(userData.address[0])
    const [precoFrete, setPrecoFrete] = useState(0)
    const [retiradaBalcao, setRetiradaBalcao] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState('item-99')


    useEffect(() => {
        if (cart) {
            const total = cart["products"].reduce(
                (prevValue, currentValue) => prevValue + (currentValue.value * currentValue.quantity), 0
            );
            setTotalProducts(total)
        }
    }, [cart]);

    const handleChangeSelectedAddress = (event) => {
        setSelectedAddress(event.target.value)

        var index = event.target.value.substr(-1)
        if (Number(index) !== 9) {
            let userAddress = userData.address[index]
            // console.log(userAddress)
            setAddress(userAddress)

            let _cep = String(userAddress.cep)
            let actualCEP = _cep.replace('-', '')
            let CepObj = CalcFrete(cepList, Number(actualCEP))

            console.log(CepObj)

            if (CepObj) {
                setPrecoFrete(CepObj.valor)
            } else {
                setPrecoFrete(0)
            }

            setRetiradaBalcao(false)
            // console.log(CepObj)
        } else {
            setPrecoFrete(0)
            setRetiradaBalcao(true)
        }
    };

    const [userAddresses, setUserAddresses] = useState([])

    useEffect(() => {
        // console.log(userData)
        setUserAddresses(userData.address)
    }, [userData])


    useEffect(() => {
        if (selectedAddress !== "item-99") {
            setDisableNext(false)
        } else {
            setDisableNext(true)
        }
    }, [selectedAddress])

    useEffect(() => {
        setOrder({
            cart, address, retiradaBalcao, totalProducts,
            cepValue: precoFrete,
            user: {
                uid: user.uid,
                email: user.email,
                firstName: userData.firstName,
                phone: userData.phone
            }
        })
    }, [cart, address, precoFrete, retiradaBalcao, totalProducts, userData, user])


    // useEffect(() => {
    //     console.log(order)
    // }, [order])


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h3' className={classes.title} color='primary'>Confirmar orçamento</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h3' className={classes.title} color='primary'>Produtos:</Typography>
                                <div className={classes.tableResponsive}>
                                    <TableContainer>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ minWidth: '250px' }}>Produto</TableCell>
                                                    <TableCell align="right">Quantidade</TableCell>
                                                    <TableCell align="right">Preço unitário</TableCell>
                                                    <TableCell align="right">Total</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cart["products"].map((product, key) => (
                                                    <TableRow key={key}>
                                                        <TableCell component="th" scope="row">
                                                            {product.name}
                                                        </TableCell>
                                                        <TableCell align="right">{product.quantity}</TableCell>
                                                        <TableCell align="right">{FormatBRL(product.value)}</TableCell>
                                                        <TableCell align="right">{FormatBRL(product.quantity * product.value)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer >
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h3' className={classes.title} color='primary'>Endereço de entrega:</Typography>
                                <Grid container spacing={2}>
                                    {userAddresses.map((item, key) => (
                                        <Grid key={key} item lg={4} md={6} xs={12}>
                                            <Card variant="outlined">
                                                <CardContent className={classes.cardContent}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Radio
                                                            checked={selectedAddress === `item-${key}`}
                                                            onChange={handleChangeSelectedAddress}
                                                            value={`item-${key}`}
                                                            name="radio-button-demo"
                                                            inputProps={{ 'aria-label': `item-${key}` }}
                                                        />
                                                        <Typography variant="body2" component="p">
                                                            {item.logradouro}, nº {item.numero}, {item.complemento}<br />
                                                            {item.bairro}, {item.localidade} - {item.uf} - {item.cep}
                                                        </Typography>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                    <Grid item lg={4} md={6} xs={12}>
                                        <Card variant="outlined">
                                            <CardContent className={classes.cardContent}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Radio
                                                        checked={selectedAddress === `item-9`}
                                                        onChange={handleChangeSelectedAddress}
                                                        value="item-9"
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                    />
                                                    <Typography variant="body2" component="p">
                                                        <b>Retirar no Balcão:</b><br />
                                                        Rua Judith Binatti, nº 181,
                                                        Liberdade, Belo Horizonte - MG 31270-250<br />
                                                    </Typography>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Typography variant='body1' style={{ textAlign: 'right', marginRight: 5 }}>Valor do frete:
                                            {retiradaBalcao ?
                                                <React.Fragment>
                                                    R$ 0,00
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    {precoFrete === 0 ?
                                                        <u style={{ color: 'red' }}>à combinar</u>
                                                        :
                                                        FormatBRL(precoFrete)
                                                    }
                                                </React.Fragment>
                                            }

                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h3' className={classes.title} color='primary'>Formas de pagamento:</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Table size="small" aria-label="formas de pagamento" className="pagamento">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" colSpan={3} scope="row"> Dinheiro / Transferência / Pix </TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts))}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 1x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.041)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 7x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.11 / 7)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 2x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.05 / 2)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 8x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.13 / 8)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 3x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) / 3 * 1.05)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 9x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.17 / 9)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 4x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) / 4 * 1.06)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 10x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.18 / 10)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 5x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) / 5 * 1.1)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 11x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.19 / 11)}</TableCell>

                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">Cartão 6x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.1 / 6)}</TableCell>
                                                    <TableCell component="th" scope="row">Cartão 12x</TableCell>
                                                    <TableCell align="right">{FormatBRL((precoFrete + totalProducts) * 1.2 / 12)}</TableCell>

                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Total dos produtos: </TableCell>
                                                    <TableCell>{FormatBRL(totalProducts)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Frete:</TableCell>
                                                    <TableCell>{precoFrete > 0 ? FormatBRL(precoFrete) : "à combinar"}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Valor Total: </TableCell>
                                                    <TableCell>{FormatBRL(precoFrete + totalProducts)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <div className={classes.formasPagamento}>
                                            <div>
                                                <b>{FormatBRL(precoFrete + totalProducts)} à vista </b>
                                            </div>
                                            <div>
                                                <b>Ou {FormatBRL((precoFrete + totalProducts) * 1.2)} em até 12x de {FormatBRL((precoFrete + totalProducts) * 1.2 / 12)} no cartão</b>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Alert severity="info" style={{ margin: '20px 0' }}>
                                    <AlertTitle>O pagamento não será realizado neste site.</AlertTitle>
                                        Ao confirmar o orçamento entraremos em contato.
                                </Alert>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
