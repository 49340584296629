
import { useState, useEffect } from "react";
import { firestore } from "../firebase";


export default function useFetchProducts() {
 
    //fetch products
    const [cep, setCEP] = useState([]);

    useEffect(() => {
        return firestore.collection('cep').onSnapshot(
            (snapshot) => {
                const listCEP = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setCEP(listCEP);
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);

    return cep;
}