import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";

import { fade, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

//material components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Hidden from '@material-ui/core/Hidden';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ListSubheader from '@material-ui/core/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ButtonBase, TextField } from "@material-ui/core";

//icon
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { grey, red, blue } from '@material-ui/core/colors';
//custom components 
// import BasketModal from "../BasketModal";

// import { connect } from 'react-redux';
// import { getNumbers } from '../../actions/getAction';
import _ from 'lodash';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '& a': {
            textDecoration: 'none',
            color: '#000',
            '&:hover': {
                color: theme.palette.common.white,
            }
        },

        '&:hover, &:focus': {
            backgroundColor: '#db2727',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    brandButton: {
        marginRight: 'auto',
        marginBottom: '.6rem',
        marginTop: '.6rem',
        '& img': {
            maxHeight: '50px',
            [theme.breakpoints.down('sm')]: {
                maxHeight: '35px',
            },
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        backgroundColor: grey[100],
    },
    inputGroupInput: {
        margin: 0,
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
        }
    },
    inputGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    }
}));

function SearchAppBar(props) {

    const { onSignUpClick } = props;
    const { onSignInClick } = props;
    const { onSignOutClick } = props;


    const cart = props.cart["products"];
    const { user } = props;
    const categories = props.categories;
    const classes = useStyles();

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [openMenu, setOpenMenu] = useState(false);

    let history = useHistory();
    const [searchQuery, setSearchQuery] = useState('')
    const [openSearch, setOpenSearch] = useState(false)

    const handleClickMenu = (itemName) => {
        setOpenMenu({ [itemName]: !openMenu[itemName] });
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickLogin = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseLogin = () => {
        setAnchorEl(null);
    };

    const loginMenuLink = () => {
        onSignInClick()
        handleCloseLogin()
    }
    const siginUpMenuLink = () => {
        onSignUpClick()
        handleCloseLogin()
    }
    const siginOutMenuLink = () => {
        onSignOutClick()
        handleCloseLogin()
    }
    const [categoriesFiltered, setCategoriesFiltered] = useState([])

    useEffect(() => {
        if (categories) {
            var filteredCat = _.filter(categories, function (o) { return o.subcategory !== null })
            // console.log(filteredCat);
            setCategoriesFiltered(filteredCat)
        }
    }, [categories])


    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            doSearch(searchQuery)
        }
    }

    function doSearch(searchQuery) {
        if (searchQuery) {
            let termo = searchQuery.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "-").toLocaleLowerCase()
            history.push(`/pesquisa?q=${termo}`);
            setSearchQuery('')
            setOpenSearch(false)
            window.scrollTo(0, 0);
        }
    }


    const subcategoriesListItem = (subcategory, slugCategory) => {
        var filteredCat = _.filter(subcategory, function (o) { return o.title !== "" })
        // console.log(filteredCat);

        return (
            filteredCat.map((subcat) => {
                var slugSubategory = subcat.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                return (
                    <ListItem button key={`sub_${subcat.id}`} component={Link} to={`/produtos/${slugCategory}/${slugSubategory}`} className={classes.nested}>
                        <ListItemText primary={subcat.title} />
                    </ListItem>
                )
            })
        )
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >

            {user ?
                <List dense={true} style={{ backgroundColor: red[400] }}>
                    <ListItem button onClick={siginOutMenuLink} style={{ color: '#fff' }}>
                        <ListItemIcon>
                            <ExitToAppIcon style={{ color: '#fff' }} />
                        </ListItemIcon>
                        <ListItemText primary='Sair' />
                    </ListItem>
                </List>
                :
                <>
                    <List dense={true} style={{ backgroundColor: red[400] }}>
                        <ListItem button onClick={onSignInClick} style={{ color: '#fff' }}>
                            <ListItemIcon>
                                <AccountCircleIcon style={{ color: '#fff' }} />
                            </ListItemIcon>
                            <ListItemText primary='Login' />
                        </ListItem>
                    </List>
                    <List dense={true} style={{ backgroundColor: blue[400] }}>
                        <ListItem button onClick={onSignUpClick} style={{ color: '#fff' }}>
                            <ListItemIcon>
                                <ExitToAppIcon style={{ color: '#fff' }} />
                            </ListItemIcon>
                            <ListItemText primary='Cadastrar' />
                        </ListItem>
                    </List>
                </>
            }

            <Divider />
            <List dense={true}>
                <ListItem button component={Link} to="/minha-conta">
                    <ListItemText primary='Meus Orçamentos' />
                </ListItem>
                <ListItem button>
                    <ListItemText primary='Minha Conta - em breve' />
                </ListItem>
            </List>
            <Divider />
            <List
                dense={true}
                component="nav"
                aria-labelledby="Produtos por Categorias"
                subheader={
                    <ListSubheader component="div" style={{ backgroundColor: grey[100], color: '#000' }}>
                        Visualizar por Categorias
                    </ListSubheader>
                }
            >
                {categoriesFiltered.map((categoryObj) => {
                    var slugCategory = categoryObj.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(new RegExp(' ', 'g'), '-').toLowerCase();
                    return (
                        <React.Fragment key={categoryObj.id}>
                            <ListItem key={`list_${categoryObj.id}`} button onClick={() => handleClickMenu(categoryObj.id)} className={classes.title}>
                                <ListItemText primary={categoryObj.title} />
                                {openMenu[categoryObj.id] ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse key={`collapse_${categoryObj.id}`} in={openMenu[categoryObj.id]} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding dense={true}>
                                    {subcategoriesListItem(categoryObj.subcategory, slugCategory)}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    )
                })
                }
            </List>
            <List dense={true} >
                <ListItem button>
                    <ListItemText primary='Quem Somos' />
                </ListItem>
                <ListItem button>
                    <ListItemText primary='Políticas do Site' />
                </ListItem>
            </List>
            <Divider />

        </div>
    );


    // const [openbasket, setOpenBasket] = React.useState(false);
    // const handleClickOpen = () => {
    //     setOpenBasket(true);
    // };


    return (
        <AppBar color="default" position="sticky">
            <Container maxWidth="lg" disableGutters={true}>
                <Toolbar>
                    <Hidden lgUp>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer('left', true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                        onOpen={toggleDrawer('left', true)}
                    >
                        {list('left')}
                    </SwipeableDrawer>
                    <ButtonBase
                        component={Link} to="/"
                        className={classes.brandButton}
                    >
                        <img src='https://firebasestorage.googleapis.com/v0/b/uai-economizei.appspot.com/o/images%2Flogo%2Fuai-logo1v5.png?alt=media&token=5b359302-0544-46c4-ac50-67ac6f3d6104'
                            alt='logo Uai Economizei'
                        />
                    </ButtonBase>
                    <Hidden mdUp>
                        <IconButton color="primary" aria-label="Pesquisar" component="span" onClick={e => setOpenSearch(!openSearch)}>
                            <SearchIcon />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Pesquisar..."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                onKeyUp={handleSearch}
                            />

                        </div>
                    </Hidden>
                    <Tooltip title="Carrinho" aria-label="Carrinho">
                        <IconButton color="primary" aria-label="carrinho de compras" component={Link} to="/orcamento">
                            {/* <IconButton color="primary" onClick={() => handleClickOpen()}> */}
                            <Badge badgeContent={cart ? cart.length : 0} color="secondary">
                                <ShoppingBasketIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    {/* <BasketModal openbasket={openbasket} /> */}
                    <Hidden smDown>
                        <React.Fragment>
                            <Tooltip title="Login" aria-label="Login">
                                <IconButton color="primary" onClick={handleClickLogin} aria-label="login - área do cliente" component="span">
                                    <AccountCircleIcon />
                                </IconButton>
                            </Tooltip>
                            <StyledMenu
                                id="login-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleCloseLogin}
                            >

                                {user ?
                                    <React.Fragment>
                                        <StyledMenuItem disabled>
                                            <Link to="/minha-conta">
                                                <ListItemText primary="Minha conta - em breve" />
                                            </Link>
                                        </StyledMenuItem>
                                        <StyledMenuItem>
                                            <Link to="/minha-conta">
                                                <ListItemText primary="Meus Orçamentos" />
                                            </Link>
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={siginOutMenuLink}>
                                            <ListItemText primary="Sair" />
                                        </StyledMenuItem>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <StyledMenuItem onClick={loginMenuLink}>
                                            <ListItemText primary="Login" />
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={siginUpMenuLink}>
                                            <ListItemText primary="Cadastrar" />
                                        </StyledMenuItem>
                                    </React.Fragment>
                                }
                            </StyledMenu>

                        </React.Fragment>
                        {/* {user ?
                            <Tooltip title="Logout" aria-label="Logout">
                                <IconButton color="secondary" onClick={onSignOutClick} aria-label="logout - desconectar sua conta" component="span">
                                    <ExitToAppIcon />
                                </IconButton>
                            </Tooltip>
                            :
                            <React.Fragment></React.Fragment>
                        } */}

                    </Hidden>
                </Toolbar>
            </Container>
            <div style={openSearch ? { padding: 10 } : { display: 'none' }}>
                <div className={classes.inputGroup}>
                    <TextField label="Pesquisar"
                        size="small"
                        margin="dense"
                        type="search"
                        variant="outlined"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                        onKeyUp={handleSearch}
                        className={classes.inputGroupInput}
                    />
                    <Button variant="contained" color="primary" disableElevation={true} style={{ borderRadius: 0, height: 40 }} onClick={() => doSearch(searchQuery)}><SearchIcon /></Button>
                </div>
            </div>
        </AppBar >
    );
}

// const mapStateToProps = state => ({
//     basketProps: state.basketState
// })

// export default connect(mapStateToProps, { getNumbers })(SearchAppBar); 

export default SearchAppBar;