const constraints = {
  firstName: {
    presence: {
      allowEmpty: false,
    },

    type: "string",
  },
 
  phone: {
    presence: {
      allowEmpty: false,
    },

    type: "string",
  },

  username: {
    length: {
      minimum: 2,
      maximum: 20,
    },

    presence: {
      allowEmpty: false,
    },

    type: "string",
  },

  emailAddress: {
    email: {
      message: "^Endereço de email é inválido",
    },

    presence: {
      allowEmpty: false,
    },

    type: "string",
  }, 

  password: {
    length: {
      minimum: 6,
    },

    presence: {
      allowEmpty: false,
    },

    type: "string",
  },
 
};

export default constraints;
